<template>
  <div class="main">
    <div class="wrapper pt-4">
      <div class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top">
        <h2 class="mb-2 text-center text-sm-left">Password Updated</h2>
        <p class="font-weight-normal text-muted">Your password was changed. Click the button below to login.</p>
        <form class="d-flex flex-column mt-2" @submit.prevent="submit" novalidate>
          <router-link class="btn btn-primary ml-auto mt-1 w-auto" :to="{ name: 'login', params: {} }">Login to Your Account</router-link>
        </form>
      </div>
      <div class="surface d-flex align-items-center justify-content-center mt-3">
        <p class="font-weight-normal mb-0 mr-2">Don't have an account?</p>
        <router-link class="text-primary font-weight-bold ml-2" :to="{ name: 'signup', params: {} }">Sign up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'password-updated'
}
</script>
